<template>
    <div>
        <avue-crud :option="tableOption" :data="page.list" :page="page" :table-loading="listLoading"
            @size-change="sizeChange" @search-change="searchChange" @refresh-change="refreshChange"
            @current-change="currentChange"  @row-save="rowSave" @row-update="rowUpdate">
            <template #status ="scope">
                <el-switch v-model="scope.row.status" :inactive-value='parseInt(valueArray[0].value)' :active-value='parseInt(valueArray[1].value)' 
                @change='enableFun(scope.row)'/>
            </template> 
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
        </avue-crud>
    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,getCurrentInstance } from 'vue'
    import { tableOption1 } from "@/const/crud/coin/chain";
    import { ElNotification } from 'element-plus'
    import { turnTimeFun } from '@/utils/util.js'
    import { getDataStyle } from '@/utils/dataBind/parmsView'

    import mixins from '@/views/mixins/page'
    const { $t } = getCurrentInstance().proxy;
    let pageObj = mixins(['getCoinList'])
    const {page,listLoading,sizeChange,currentChange,getList,successFun} = pageObj
    const tableOption = ref({});tableOption.value = tableOption1;
    
    const valueArray = ref([])
    getDataStyle('enable_status',(e)=>{valueArray.value = e})

    getList(1)
    const searchChange = (form, done)=>{
        page.currentPage = 1;
        getList(form);
        done();
    }
    const refreshChange = ()=>{
        getList();
    }
    const enableFun = (e)=>{
        if(e.chainId){
            let s_ = e.status === 0?0:1
            listLoading.value = true
            Api_.updateCoinStatus({
                chainId: e.chainId,
                status: s_
            }).then(res=>{
                listLoading.value = false
                if(res.code === 0){
                    ElNotification({
                        message: e.status === 1?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                    getList()
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }
    }
    const rowSave = (row,done)=>{
        const datas = JSON.parse(JSON.stringify(row));
        Api_.addCoinObj(datas).then((res)=>{
            if(res.code === 0){
                successFun()
                ElNotification({
                    message: $t('alert.succAdd'),
                    type: 'success',
                })
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
        done()
    }
    const rowUpdate = (row,index,done)=>{
        Api_.updateCoinObj(row).then(res=>{
            if(res.code === 0){
                successFun()
                ElNotification({
                    message: $t('alert.succUpdate'),
                    type: 'success',
                })
            }else{
                ElNotification({
                    message: res.msg,
                    type: 'error',
                })
            }
        })
        done()
    }
</script>
