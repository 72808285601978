import il8n from '@/assets/language/index.js';
import website from '@/config/website'
const { t } = il8n.global;

export const tableOption1 = {
    menu:true,
    border: true,
    stripe: true,
    searchMenuSpan: 6,
    menuWidth: 100,
    viewBtn: false,
    columnBtn: false,
    addBtn: true,
    editBtn: true,
    delBtn: false,
    refreshBtn: false,
    column: [{
        width: 100,
        label: t('chain.text2'),
        prop: 'chain',
        search:true,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        }]

    }, {
        width: 130,
        label: t('chain.text3'),
        prop: 'baseCoin',
        search:true,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        }]
    }, {
        width: 210,
        label: t('chain.text4'),
        prop: 'fastConfirms', 
        labelWidth: 130,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        }]
    }, {
        width: 240,
        label: t('chain.text5'),
        prop: 'safeConfirms',
        labelWidth: 130,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        }]
    }, {
        width: 190,
        label: t('chain.text6'),
        prop: 'gasTrackerApi',
        labelWidth: 130,
        overHidden: true,
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        }]
    }, {
        width: 170,
        label: '地址正则',
        prop: 'addrRegexp',
        rules: [{
            required: true,
            message: "请填写",
            trigger: "blur"
        }]
    },{
        width: 200,
        labelWidth: 120,
        searchLabelWidth: 120,
        label: t('chain.text7'),
        prop: 'chainList',
        search:true,
        span: 12,
        type: 'select',
        dicUrl: `${website.urls}/admin/dict/type/chain_list_type`,
        dicFormatter: (res)=>{
            res.forEach(function(item) {
                item.value = parseInt(item.value)
            });
            return res
        },
        props: {
            label: 'label',
            value: 'value'
        },
        filterable: true,
        rules: [{
            required: true,
            message: "请选择",
            trigger: "blur"
        }]
    }, {
        width: 130,
        label: t('chain.text8'),
        prop: 'hasToken',
        type: 'radio',
        search:true,
        border: true,
        span: 12,
        dicUrl: `${website.urls}/admin/dict/type/has_token`,
        dicFormatter: (res)=>{
            res.forEach(function(item) {
                item.value = parseInt(item.value)
            });
            return res
        },
        props: {
            label: 'label',
            value: 'value'
        },
        value: '1',
        filterable: true,
        rules: [{
            required: true,
            message: "请选择",
            trigger: "blur"
        }]
    }, 
    {
        width: 160,
        label: t('chain.text9'),
        prop: 'createTime',
        // searchLabelWidth: 120,
        searchType: 'datetimerange',
        defaultTime:['00:00:00', '23:59:59'],
        searchRange: true,
        search:true,
        display: false
    }, 
    {
        width: 160,
        label: t('chain.text10'),
        prop: 'updateTime',
        display: false
    },{
        width: 120,
        label: t('chain.text11'),
        prop: 'updateUser',
        overHidden: true,
        display: false
    },{
        // width: 120,
        label: t('chain.text12'),
        prop: 'status',
        fixed:'right',
        display: false
    }]
}